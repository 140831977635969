import React, { useContext, useEffect, useReducer } from "react";
import { Navigate } from "react-router-dom";
import { Data } from "../../Data";
import { toast } from "react-toastify";
import Axios from "axios";
import { getError } from "../../utils/utils";
import { Spinner } from "react-bootstrap";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default function ProtectedContent({ children }) {
    const { state, dispatch: ctxDispatch } = useContext(Data);
    const { userInfo } = state;
    const [{ loading }, dispatch] = useReducer(reducer, {
        loading: true,
    });

    useEffect(() => {
        if (userInfo) {
            const handleLogout = () => {
                ctxDispatch({ type: "USER_SIGNOUT" });
                const loggedInUser = localStorage.getItem("userInfo");
                if (loggedInUser) {
                    localStorage.removeItem("userInfo");
                }
            };
            const authHandler = async () => {
                dispatch({ type: "FETCH_REQUEST" });
                try {
                    await Axios.post(
                        "/api/auth/",
                        {
                            request: "auth",
                        },
                        {
                            headers: {
                                authorization: `Bearer ${userInfo.token}`,
                            },
                        }
                    );
                    dispatch({ type: "FETCH_SUCCESS" });
                } catch (err) {
                    dispatch({ type: "FETCH_FAIL" });
                    toast.error(getError(err));
                    handleLogout();
                }
            };
            authHandler();
        }
    }, [userInfo, ctxDispatch]);

    if (userInfo) {
        return loading ? (
            <div className="loading-box-global">
                <Spinner
                    animation="border"
                    role="status"
                    className="spinner-border-custom"
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        ) : (
            children
        );
    } else {
        return <Navigate to="/login" />;
    }
}
