import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { toast } from "react-toastify";
import { Data } from "../../Data";
import { NavDropdown } from "react-bootstrap";
import { Navigate } from "react-router-dom";

function TopBar() {
    const { state, dispatch: ctxDispatch } = useContext(Data);
    const { userInfo } = state;

    const handleLogout = () => {
        try {
            ctxDispatch({ type: "USER_SIGNOUT" });
            const loggedInUser = localStorage.getItem("userInfo");
            if (loggedInUser) {
                localStorage.removeItem("userInfo");
            }
            window.location.href = "/login?message=logout";
            toast.success("Úspěšně odhlášen!");
        } catch (err) {
            console.error(err);
            toast.error("Chyba při odhlašování!");
        }
    };
    if (userInfo) {
        return (
            <Navbar expand="lg" bg="primary" variant="dark">
                <Container>
                    <Link to="/" className="navbar-brand">
                        <img src={logo} width="120" alt="logo" />
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav>
                            <NavLink
                                to="/"
                                className={(navData) =>
                                    navData.isActive
                                        ? "active nav-link"
                                        : "nav-link"
                                }
                            >
                                Domů
                            </NavLink>
                            <NavLink
                                to="/documents"
                                className={(navData) =>
                                    navData.isActive
                                        ? "active nav-link nav-link-tag-new"
                                        : "nav-link nav-link-tag-new"
                                }
                            >
                                Dokumenty
                            </NavLink>
                            <NavLink
                                to="/requests"
                                className={(navData) =>
                                    navData.isActive
                                        ? "active nav-link"
                                        : "nav-link"
                                }
                            >
                                Žádosti
                            </NavLink>

                            <NavDropdown
                                title={
                                    <FontAwesomeIcon
                                        className="ms-1"
                                        icon={faUser}
                                    />
                                }
                                id="basic-nav-dropdown"
                            >
                                <NavLink
                                    to="/profile"
                                    className={(navData) =>
                                        navData.isActive
                                            ? "active dropdown-item"
                                            : "dropdown-item"
                                    }
                                >
                                    Údaje
                                </NavLink>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    Odhlásit&#32;
                                    <FontAwesomeIcon
                                        className="ms-1"
                                        icon={faRightFromBracket}
                                    />
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    } else {
        return <Navigate to="/login" />;
    }
}

export default TopBar;
