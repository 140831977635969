import { Helmet } from "react-helmet-async";
import TopBar from "../components/TopBar";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Badge, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Data } from "../../Data";
import Axios from "axios";
import { toast } from "react-toastify";
import { getDate, getError } from "../../utils/utils";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";

import {
    faArrowUpRightFromSquare,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, infoData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        case "SIGN_REQUEST":
            return { ...state, singLoading: true };
        case "SIGN_SUCCESS":
            return { ...state, signData: action.payload, singLoading: false };
        case "SIGN_FAIL":
            return { ...state, singLoading: false };
        case "SIGN_REQUEST_BANKID":
            return { ...state, bankidLoading: true };
        case "SIGN_SUCCESS_BANKID":
            return { ...state, bankidLoading: false };
        case "SIGN_FAIL_BANKID":
            return { ...state, bankidLoading: false };
        default:
            return state;
    }
};

export default function Documents() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [
        { loading, singLoading, bankidLoading, signData, infoData },
        dispatch,
    ] = useReducer(reducer, {
        infoData: [],
        signData: [],
        singLoading: false,
        loading: true,
        bankidLoading: false,
    });

    const [showAuth, setShowAuth] = useState(false);
    const handleCloseAuth = () => {
        setShowAuth(false);
    };

    const { state } = useContext(Data);
    const { userInfo } = state;

    const signDocument = async (e, refNo, documentId, currentValue) => {
        e.preventDefault();

        dispatch({ type: "SIGN_REQUEST" });
        try {
            const { data } = await Axios.post(
                "/api/documents/sign",
                {
                    id: documentId,
                    refNo: refNo,
                    nextPamentDate: e.target.elements["default-radio"].value
                        ? parseInt(e.target.elements["default-radio"].value)
                        : null,
                    currentValue: parseInt(currentValue),
                },
                {
                    headers: {
                        authorization: `Bearer ${userInfo.token}`,
                    },
                }
            );
            dispatch({ type: "SIGN_SUCCESS", payload: data });
            toast.info("Oveřte se prosím přes BankID");
            setShowAuth(true);
        } catch (err) {
            dispatch({ type: "SIGN_FAIL" });
            toast.error(getError(err));
        }
    };

    useEffect(() => {
        if (searchParams.has("code")) {
            const queryCode = searchParams.get("code");
            dispatch({ type: "SIGN_REQUEST_BANKID" });
            const bankidHandler = async () => {
                try {
                    const { data } = await Axios.post(
                        "/api/documents/callback",
                        {
                            code: queryCode,
                        },
                        {
                            headers: {
                                authorization: `Bearer ${userInfo.token}`,
                            },
                        }
                    );
                    console.log(data);
                    dispatch({ type: "SIGN_SUCCESS_BANKID" });

                    const fetchData = async () => {
                        dispatch({ type: "FETCH_REQUEST" });
                        try {
                            const { data } = await Axios.post(
                                "/api/documents/info",
                                {
                                    request: "info",
                                },
                                {
                                    headers: {
                                        authorization: `Bearer ${userInfo.token}`,
                                    },
                                }
                            );
                            dispatch({ type: "FETCH_SUCCESS", payload: data });
                            //console.log(data);
                        } catch (err) {
                            dispatch({ type: "FETCH_FAIL" });
                            toast.error(getError(err));
                        }
                    };
                    fetchData();

                    toast.success("Dokument úspěšně podepsán");
                } catch (err) {
                    dispatch({ type: "SIGN_FAIL_BANKID" });
                    toast.error(getError(err));
                }
            };
            bankidHandler();
            searchParams.delete("code");
            if (searchParams.has("traceId")) {
                searchParams.delete("traceId");
            }
            if (searchParams.has("state")) {
                searchParams.delete("state");
            }
            setSearchParams(searchParams);
            //console.log(accessToken);
        }
    }, [userInfo, searchParams, setSearchParams]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "FETCH_REQUEST" });
            try {
                const { data } = await Axios.post(
                    "/api/documents/info",
                    {
                        request: "info",
                    },
                    {
                        headers: {
                            authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                );
                dispatch({ type: "FETCH_SUCCESS", payload: data });
                //console.log(data);
            } catch (err) {
                dispatch({ type: "FETCH_FAIL" });
                toast.error(getError(err));
            }
        };
        fetchData();
    }, [userInfo]);

    return (
        <>
            <Helmet>
                <title>Dokumenty | De Vries Group, a.s.</title>
            </Helmet>
            <TopBar />
            <main>
                <Modal
                    show={showAuth}
                    onHide={handleCloseAuth}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h2 className="mb-0">{signData.documentName}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center d-flex flex-column justify-content-center align-items-center">
                            <div className="mb-2 w-100 text-center d-flex flex-column justify-content-center align-items-center">
                                <h3 className="mb-2">Náhled</h3>
                                <embed
                                    className="d-block d-lg-none mb-4"
                                    src={signData.documentURI}
                                    width="100%"
                                    height="400px"
                                />
                                <embed
                                    className="d-none d-lg-block mb-4"
                                    src={signData.documentURI}
                                    width="500px"
                                    height="650px"
                                />
                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                    <Button
                                        target="_blank"
                                        type="primary"
                                        href={signData.documentURI}
                                    >
                                        Zobrazit&#32;
                                        <FontAwesomeIcon
                                            className="ms-2"
                                            icon={faArrowUpRightFromSquare}
                                        />
                                    </Button>
                                    <Button
                                        target="_blank"
                                        type="primary"
                                        href={signData.documentURI}
                                        download
                                    >
                                        Stáhnout&#32;
                                        <FontAwesomeIcon
                                            className="ms-2"
                                            icon={faDownload}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a
                            href={signData.url}
                            className="button button-bankid mt-1"
                        >
                            <svg
                                width="190"
                                height="28"
                                viewBox="0 0 190 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-30 w-70 pr-10 mr-10 border-r border-white-100"
                            >
                                <path
                                    d="M162.165 7.511h8.601c6.777 0 10.165 5.634 10.165 11.216 0 5.634-3.388 11.215-10.165 11.215h-8.601v-2.608h3.649V10.12h-3.649V7.51zm-4.952 29.473h13.814c12.354 0 18.506-9.128 18.506-18.257 0-9.13-6.152-18.258-18.506-18.258h-13.814v36.515zM143.139 7.511h7.819V.47h-7.819v7.042zm0 2.609h7.819v26.864h-7.819V10.12zm-32.528 26.864v-8.972h2.606l7.82 8.972h7.767v-2.608l-9.852-11.059 9.591-10.59V10.12h-7.454l-7.872 8.972v3.6h-2.606V.468h-7.819v36.515h7.819zm-21.633 0h7.819V20.71c0-6.73-4.9-11.111-10.374-11.111-2.294 0-4.691.782-6.829 2.452v3.025h-2.606V10.12H70.21v26.864h7.82V22.117c0-3.755 2.867-5.738 5.681-5.738 2.659 0 5.266 1.774 5.266 5.478v15.127zM49.465 16.275c3.492 0 6.411 2.817 6.411 7.25 0 4.435-2.919 7.252-6.411 7.252-3.493 0-6.62-2.817-6.62-7.251s3.127-7.251 6.62-7.251zm-14.7 7.25c0 8.66 6.203 13.981 12.354 13.981 2.45 0 4.9-.835 6.933-2.712v-2.765h2.606v4.955h7.298V10.12h-7.298v4.955h-2.606v-2.764c-2.033-1.879-4.483-2.713-6.933-2.713-6.151 0-12.355 5.32-12.355 13.928zM8.23 6.99h9.488c2.919 0 4.43 2.086 4.43 4.173 0 2.086-1.511 4.173-4.43 4.173h-6.881v6.781h7.558c2.92 0 4.431 2.087 4.431 4.174 0 2.086-1.512 4.173-4.43 4.173H8.23V6.99zm19.6 12.78h-4.274v-2.608h3.753c2.138-1.774 3.232-4.33 3.232-6.938 0-4.851-3.805-9.755-11.729-9.755H.151v36.515h19.392c7.976 0 11.834-5.06 11.834-10.015 0-2.765-1.2-5.425-3.545-7.199z"
                                    fill="#fff"
                                ></path>
                            </svg>
                            Podepsat
                        </a>
                    </Modal.Footer>
                </Modal>
                <section className="documents">
                    <Container>
                        <Row className="gy-4">
                            <Col xs={12} lg={8}>
                                <h2 className="mb-0">K podpisu</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    infoData.cases.map((item, index) => (
                                        <div key={index} className="ref-item">
                                            <div className="d-flex flex-wrap row-gap-0 column-gap-4 mb-4">
                                                <div>
                                                    <span className="title-perex">
                                                        Referenční číslo
                                                    </span>
                                                    <h3 className="mb-2">
                                                        {item.refNo}
                                                    </h3>
                                                </div>
                                                <div>
                                                    <span className="title-perex">
                                                        Věřitel
                                                    </span>
                                                    <h3 className="mb-2">
                                                        {item.supplier
                                                            ? item.supplier
                                                            : "Není vyplněn"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="row gx-0 gx-md-3 gy-3">
                                                {item.signId.length > 0 ? (
                                                    item.signId.map(
                                                        (sign, signIndex) => (
                                                            <div
                                                                key={signIndex}
                                                                className="col col-12 col-md-6"
                                                            >
                                                                <div
                                                                    className={
                                                                        !sign.isSigned
                                                                            ? "document-item document-item-not-signed"
                                                                            : "document-item"
                                                                    }
                                                                >
                                                                    <span className="title-perex">
                                                                        Název
                                                                    </span>
                                                                    <h4 className="mb-4">
                                                                        {
                                                                            sign.name
                                                                        }
                                                                    </h4>
                                                                    {sign.isSigned ? (
                                                                        <>
                                                                            <Badge bg="success">
                                                                                Úspěšně
                                                                                podepsáno
                                                                            </Badge>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {sign.settingsEnabled && (
                                                                                <div className="value-box my-3">
                                                                                    {sign.valueFixed ? (
                                                                                        sign.valueFixedType ===
                                                                                        "asc_price_months" ? (
                                                                                            <>
                                                                                                {parseFloat(
                                                                                                    item.amount
                                                                                                ) <=
                                                                                                parseFloat(
                                                                                                    sign.nearestKey
                                                                                                ) ? (
                                                                                                    sign.nearestValue >
                                                                                                    1 ? (
                                                                                                        <form
                                                                                                            onSubmit={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                signDocument(
                                                                                                                    e,
                                                                                                                    item.refNo,
                                                                                                                    sign.id,
                                                                                                                    document.getElementById(
                                                                                                                        "value" +
                                                                                                                            index +
                                                                                                                            index +
                                                                                                                            index +
                                                                                                                            index
                                                                                                                    )
                                                                                                                        ?.textContent
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <div>
                                                                                                                <div>
                                                                                                                    {parseFloat(
                                                                                                                        item.amount
                                                                                                                    ) <=
                                                                                                                    parseFloat(
                                                                                                                        sign.nearestKey
                                                                                                                    ) ? (
                                                                                                                        <>
                                                                                                                            <p>
                                                                                                                                <strong>
                                                                                                                                    Počet
                                                                                                                                    splátek:
                                                                                                                                </strong>{" "}
                                                                                                                                {
                                                                                                                                    sign.nearestValue
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                            <p>
                                                                                                                                <strong>
                                                                                                                                    První
                                                                                                                                    splátka:
                                                                                                                                </strong>{" "}
                                                                                                                                Za
                                                                                                                                15
                                                                                                                                dní
                                                                                                                                od
                                                                                                                                podpisu
                                                                                                                                smlouvy
                                                                                                                            </p>
                                                                                                                            <p>
                                                                                                                                <strong>
                                                                                                                                    Částka
                                                                                                                                    za
                                                                                                                                    1
                                                                                                                                    splátku:
                                                                                                                                </strong>{" "}
                                                                                                                                {parseFloat(
                                                                                                                                    item.amount /
                                                                                                                                        sign.nearestValue
                                                                                                                                ).toLocaleString(
                                                                                                                                    "cs-CZ",
                                                                                                                                    {
                                                                                                                                        style: "decimal",
                                                                                                                                        minimumFractionDigits: 2,
                                                                                                                                        maximumFractionDigits: 2,
                                                                                                                                        useGrouping: true,
                                                                                                                                    }
                                                                                                                                )}{" "}
                                                                                                                                Kč
                                                                                                                            </p>
                                                                                                                            <p>
                                                                                                                                <strong>
                                                                                                                                    Celková
                                                                                                                                    částka:
                                                                                                                                </strong>{" "}
                                                                                                                                {parseFloat(
                                                                                                                                    item.amount
                                                                                                                                ).toLocaleString(
                                                                                                                                    "cs-CZ",
                                                                                                                                    {
                                                                                                                                        style: "decimal",
                                                                                                                                        minimumFractionDigits: 2,
                                                                                                                                        maximumFractionDigits: 2,
                                                                                                                                        useGrouping: true,
                                                                                                                                    }
                                                                                                                                )}{" "}
                                                                                                                                Kč
                                                                                                                            </p>
                                                                                                                            {sign.nearestValue >
                                                                                                                            1 ? (
                                                                                                                                <>
                                                                                                                                    <p>
                                                                                                                                        <strong>
                                                                                                                                            Další
                                                                                                                                            splátky:{" "}
                                                                                                                                        </strong>{" "}
                                                                                                                                    </p>
                                                                                                                                    <div
                                                                                                                                        className="mb-3"
                                                                                                                                        id={`radio-select-${index}-${signIndex}`}
                                                                                                                                    >
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`1`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 1. den v měsíci`}
                                                                                                                                            id={`default-radio-1-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`5`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 5. den v měsíci`}
                                                                                                                                            id={`default-radio-2-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`10`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 10. den v měsíci`}
                                                                                                                                            id={`default-radio-3-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`15`}
                                                                                                                                            defaultChecked={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 15. den v měsíci`}
                                                                                                                                            id={`default-radio-4-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`20`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 20. den v měsíci`}
                                                                                                                                            id={`default-radio-5-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`24`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 24. den v měsíci`}
                                                                                                                                            id={`default-radio-6-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                        <Form.Check
                                                                                                                                            type={
                                                                                                                                                "radio"
                                                                                                                                            }
                                                                                                                                            value={`28`}
                                                                                                                                            name="default-radio"
                                                                                                                                            label={`Vždy 28. den v měsíci`}
                                                                                                                                            id={`default-radio-7-${index}-${signIndex}`}
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    <p>
                                                                                                                                        Zaplatíte
                                                                                                                                        pouze
                                                                                                                                        1
                                                                                                                                        splátku
                                                                                                                                        za
                                                                                                                                        15
                                                                                                                                        dní
                                                                                                                                        od
                                                                                                                                        podpisu
                                                                                                                                        smlouvy.
                                                                                                                                    </p>
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <p>
                                                                                                                                Omlouváme
                                                                                                                                se,
                                                                                                                                ale
                                                                                                                                splátky
                                                                                                                                pro
                                                                                                                                Vás
                                                                                                                                nejsou
                                                                                                                                dostupné,
                                                                                                                                prosím
                                                                                                                                kontaktujte
                                                                                                                                administrátora
                                                                                                                                webu.
                                                                                                                            </p>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                className="button button-bankid mt-1"
                                                                                                            >
                                                                                                                <svg
                                                                                                                    width="190"
                                                                                                                    height="28"
                                                                                                                    viewBox="0 0 190 38"
                                                                                                                    fill="none"
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                    className="h-30 w-70 pr-10 mr-10 border-r border-white-100"
                                                                                                                >
                                                                                                                    <path
                                                                                                                        d="M162.165 7.511h8.601c6.777 0 10.165 5.634 10.165 11.216 0 5.634-3.388 11.215-10.165 11.215h-8.601v-2.608h3.649V10.12h-3.649V7.51zm-4.952 29.473h13.814c12.354 0 18.506-9.128 18.506-18.257 0-9.13-6.152-18.258-18.506-18.258h-13.814v36.515zM143.139 7.511h7.819V.47h-7.819v7.042zm0 2.609h7.819v26.864h-7.819V10.12zm-32.528 26.864v-8.972h2.606l7.82 8.972h7.767v-2.608l-9.852-11.059 9.591-10.59V10.12h-7.454l-7.872 8.972v3.6h-2.606V.468h-7.819v36.515h7.819zm-21.633 0h7.819V20.71c0-6.73-4.9-11.111-10.374-11.111-2.294 0-4.691.782-6.829 2.452v3.025h-2.606V10.12H70.21v26.864h7.82V22.117c0-3.755 2.867-5.738 5.681-5.738 2.659 0 5.266 1.774 5.266 5.478v15.127zM49.465 16.275c3.492 0 6.411 2.817 6.411 7.25 0 4.435-2.919 7.252-6.411 7.252-3.493 0-6.62-2.817-6.62-7.251s3.127-7.251 6.62-7.251zm-14.7 7.25c0 8.66 6.203 13.981 12.354 13.981 2.45 0 4.9-.835 6.933-2.712v-2.765h2.606v4.955h7.298V10.12h-7.298v4.955h-2.606v-2.764c-2.033-1.879-4.483-2.713-6.933-2.713-6.151 0-12.355 5.32-12.355 13.928zM8.23 6.99h9.488c2.919 0 4.43 2.086 4.43 4.173 0 2.086-1.511 4.173-4.43 4.173h-6.881v6.781h7.558c2.92 0 4.431 2.087 4.431 4.174 0 2.086-1.512 4.173-4.43 4.173H8.23V6.99zm19.6 12.78h-4.274v-2.608h3.753c2.138-1.774 3.232-4.33 3.232-6.938 0-4.851-3.805-9.755-11.729-9.755H.151v36.515h19.392c7.976 0 11.834-5.06 11.834-10.015 0-2.765-1.2-5.425-3.545-7.199z"
                                                                                                                        fill="#fff"
                                                                                                                    ></path>
                                                                                                                </svg>
                                                                                                                Zobrazit
                                                                                                                a
                                                                                                                podepsat
                                                                                                            </button>
                                                                                                        </form>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <Alert
                                                                                                                className="mb-0"
                                                                                                                variant="warning"
                                                                                                            >
                                                                                                                <b>
                                                                                                                    Prosím
                                                                                                                    kontaktujte
                                                                                                                    nás
                                                                                                                    pro
                                                                                                                    podepsání
                                                                                                                </b>
                                                                                                                <br />
                                                                                                                Tel.:
                                                                                                                +420
                                                                                                                226
                                                                                                                281
                                                                                                                001
                                                                                                                <br />
                                                                                                                E-mail:
                                                                                                                pohledavky@devriesgroup.cz
                                                                                                            </Alert>
                                                                                                        </>
                                                                                                    )
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <>
                                                                                                            <Alert
                                                                                                                className="mb-0"
                                                                                                                variant="warning"
                                                                                                            >
                                                                                                                <b>
                                                                                                                    Prosím
                                                                                                                    kontaktujte
                                                                                                                    nás
                                                                                                                    pro
                                                                                                                    podepsání
                                                                                                                </b>
                                                                                                                <br />
                                                                                                                Tel.:
                                                                                                                +420
                                                                                                                226
                                                                                                                281
                                                                                                                001
                                                                                                                <br />
                                                                                                                E-mail:
                                                                                                                pohledavky@devriesgroup.cz
                                                                                                            </Alert>
                                                                                                        </>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <p>
                                                                                                    Chyba
                                                                                                    -
                                                                                                    tato
                                                                                                    funkce
                                                                                                    ještě
                                                                                                    není
                                                                                                    dostupná
                                                                                                </p>
                                                                                            </>
                                                                                        )
                                                                                    ) : (
                                                                                        <>
                                                                                            <label
                                                                                                htmlFor="valueRange"
                                                                                                className="form-label"
                                                                                            >
                                                                                                {
                                                                                                    sign.settingsName
                                                                                                }
                                                                                                <i className="small text-muted">
                                                                                                    (posunutím
                                                                                                    vyberete)
                                                                                                </i>
                                                                                            </label>
                                                                                            <div className="d-flex align-items-center gap-2">
                                                                                                <span className="side-values">
                                                                                                    {
                                                                                                        sign.valueMin
                                                                                                    }
                                                                                                </span>
                                                                                                <input
                                                                                                    type="range"
                                                                                                    onInput={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        document.getElementById(
                                                                                                            "value" +
                                                                                                                index +
                                                                                                                index +
                                                                                                                index +
                                                                                                                index
                                                                                                        ).textContent =
                                                                                                            e.target.value;
                                                                                                        document.getElementById(
                                                                                                            "partValue" +
                                                                                                                index +
                                                                                                                index +
                                                                                                                index +
                                                                                                                index
                                                                                                        ).textContent =
                                                                                                            (
                                                                                                                item.amount /
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value
                                                                                                            ).toLocaleString(
                                                                                                                "cs-CZ",
                                                                                                                {
                                                                                                                    style: "decimal",
                                                                                                                    minimumFractionDigits: 2,
                                                                                                                    maximumFractionDigits: 2,
                                                                                                                    useGrouping: true,
                                                                                                                }
                                                                                                            );
                                                                                                    }}
                                                                                                    className="form-range"
                                                                                                    defaultValue={
                                                                                                        sign.valueDefault
                                                                                                    }
                                                                                                    min={
                                                                                                        sign.valueMin
                                                                                                    }
                                                                                                    max={
                                                                                                        sign.valueMax
                                                                                                    }
                                                                                                    step="1"
                                                                                                    id="valueRange"
                                                                                                />
                                                                                                <span className="side-values">
                                                                                                    {
                                                                                                        sign.valueMax
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <span className="info-text currentValue">
                                                                                                Právě
                                                                                                nastaveno:{" "}
                                                                                                <span
                                                                                                    className="fw-bold"
                                                                                                    id={
                                                                                                        "value" +
                                                                                                        index +
                                                                                                        index +
                                                                                                        index +
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        sign.valueDefault
                                                                                                    }
                                                                                                </span>{" "}
                                                                                                splátek
                                                                                            </span>
                                                                                            <span className="info-text partValue">
                                                                                                Částka
                                                                                                na
                                                                                                splátku:{" "}
                                                                                                <span
                                                                                                    className="fw-bold"
                                                                                                    id={
                                                                                                        "partValue" +
                                                                                                        index +
                                                                                                        index +
                                                                                                        index +
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {(
                                                                                                        item.amount /
                                                                                                        sign.valueDefault
                                                                                                    ).toLocaleString(
                                                                                                        "cs-CZ",
                                                                                                        {
                                                                                                            style: "decimal",
                                                                                                            minimumFractionDigits: 2,
                                                                                                            maximumFractionDigits: 2,
                                                                                                            useGrouping: true,
                                                                                                        }
                                                                                                    )}
                                                                                                </span>{" "}
                                                                                                <strong>
                                                                                                    {item.currency ===
                                                                                                    "CZK"
                                                                                                        ? "Kč"
                                                                                                        : ` ${item.currency}`}
                                                                                                </strong>
                                                                                            </span>
                                                                                            <span className="info-text allValue">
                                                                                                Celková
                                                                                                částka:{" "}
                                                                                                <strong>
                                                                                                    {item.amount.toLocaleString(
                                                                                                        "cs-CZ",
                                                                                                        {
                                                                                                            style: "decimal",
                                                                                                            minimumFractionDigits: 2,
                                                                                                            maximumFractionDigits: 2,
                                                                                                            useGrouping: true,
                                                                                                        }
                                                                                                    )}{" "}
                                                                                                    {item.currency ===
                                                                                                    "CZK"
                                                                                                        ? "Kč"
                                                                                                        : ` ${item.currency}`}
                                                                                                </strong>
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div
                                                        key={index}
                                                        className="col col-12"
                                                    >
                                                        <Badge bg={"dark"}>
                                                            Nic k podpisu
                                                        </Badge>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </Col>
                            <Col xs={12} lg={4}>
                                <h2 className="mb-0">Podepsané</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : infoData.signedDocuments.length > 0 ? (
                                    infoData.signedDocuments.map(
                                        (item, index) => (
                                            <div
                                                key={index}
                                                className="signed-item"
                                            >
                                                <span className="title-perex">
                                                    Název
                                                </span>
                                                <h3 className="mb-0">
                                                    {item.title}
                                                </h3>
                                                <span className="time">
                                                    {getDate(item.date)}
                                                </span>
                                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                                    <Button
                                                        target="_blank"
                                                        type="primary"
                                                        href={item.uri}
                                                    >
                                                        Zobrazit&#32;
                                                        <FontAwesomeIcon
                                                            className="ms-2"
                                                            icon={
                                                                faArrowUpRightFromSquare
                                                            }
                                                        />
                                                    </Button>
                                                    <Button
                                                        target="_blank"
                                                        type="primary"
                                                        href={item.uri}
                                                        download
                                                    >
                                                        Stáhnout&#32;
                                                        <FontAwesomeIcon
                                                            className="ms-2"
                                                            icon={faDownload}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <>
                                        <Alert variant={"secondary"}>
                                            Nemáte žádné podepsané dokumenty
                                        </Alert>
                                    </>
                                )}
                            </Col>
                        </Row>
                        {bankidLoading ? (
                            <div className="loading-box-global">
                                <Spinner
                                    animation="border"
                                    role="status"
                                    className="spinner-border-custom"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </Spinner>
                                <h1>Načítání, prosím vyčkejte...</h1>
                            </div>
                        ) : (
                            <></>
                        )}
                        {singLoading ? (
                            <div className="loading-box-global">
                                <Spinner
                                    animation="border"
                                    role="status"
                                    className="spinner-border-custom"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </Spinner>
                                <h1>Načítání, prosím vyčkejte...</h1>
                            </div>
                        ) : (
                            <></>
                        )}
                    </Container>
                </section>
            </main>
        </>
    );
}
