import { Badge, Col, Container, Row, Spinner } from "react-bootstrap";
import TopBar from "../components/TopBar";
import React, { useContext, useEffect, useReducer } from "react";
import { Data } from "../../Data";
import { toast } from "react-toastify";
import { getError } from "../../utils/utils";
import Axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet-async";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, infoData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default function Home() {
    const [{ loading, infoData }, dispatch] = useReducer(reducer, {
        infoData: [],
        loading: true,
    });
    const { state } = useContext(Data);
    const { userInfo } = state;
    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "FETCH_REQUEST" });
            try {
                const { data } = await Axios.post(
                    "/api/info/",
                    {
                        request: "info",
                    },
                    {
                        headers: {
                            authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                );
                dispatch({ type: "FETCH_SUCCESS", payload: data });
                //console.log(data);
            } catch (err) {
                dispatch({ type: "FETCH_FAIL" });
                toast.error(getError(err));
            }
        };
        fetchData();
    }, [userInfo]);
    return (
        <>
            <Helmet>
                <title>Přehled | De Vries Group, a.s.</title>
            </Helmet>
            <TopBar />
            <main>
                <section className="home">
                    <Container>
                        <Row className="gy-4">
                            <Col xs={12} md={9}>
                                <h2 className="mb-0">Pohledávky</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        <div className="accordion-heading">
                                            <Row xs={3} md={4}>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Referenční číslo
                                                    </h3>
                                                </Col>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Věřitel
                                                    </h3>
                                                </Col>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Částka
                                                    </h3>
                                                </Col>
                                                <Col className="d-none d-md-block">
                                                    <h3 className="mb-0">
                                                        Stav
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Accordion alwaysOpen>
                                            {infoData.cases.map((item) =>
                                                item.iSState === "Exekuce" ? (
                                                    <Accordion.Item
                                                        key={item.iSRefno}
                                                        eventKey={item.iSRefno}
                                                    >
                                                        <Accordion.Header>
                                                            <div>
                                                                <Row
                                                                    xs={3}
                                                                    md={4}
                                                                >
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            <Badge bg="secondary">
                                                                                {
                                                                                    item.iSRefno
                                                                                }
                                                                            </Badge>
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            {
                                                                                item.iSSupplier
                                                                            }
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            --
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center d-none d-md-block">
                                                                        <h4 className="mb-0">
                                                                            <div
                                                                                className="badge"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        item.stateColor,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.iSState
                                                                                }
                                                                            </div>
                                                                        </h4>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="col-md-8 col-sm-12">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Stav
                                                                                    Případu:
                                                                                </td>
                                                                                <td>
                                                                                    <div
                                                                                        className="badge"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                item.stateColor,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.iSState
                                                                                        }
                                                                                    </div>{" "}
                                                                                    -{" "}
                                                                                    {
                                                                                        item.iSSubstate
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Splatnost:
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.dueDate
                                                                                    }{" "}
                                                                                    {
                                                                                        item.DPD
                                                                                    }{" "}
                                                                                    dní
                                                                                    po
                                                                                    splatnosti
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <strong>
                                                                                        Ve
                                                                                        věci
                                                                                        Vašeho
                                                                                        závazku
                                                                                        vůči{" "}
                                                                                        {
                                                                                            item.iSSupplier
                                                                                        }{" "}
                                                                                        kontaktujte
                                                                                        příslušný
                                                                                        exekutorský
                                                                                        úřad.
                                                                                        Pokud
                                                                                        nemáte
                                                                                        tyto
                                                                                        informace,
                                                                                        obraťte
                                                                                        se
                                                                                        na
                                                                                        naše
                                                                                        operátory.
                                                                                    </strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ) : item.iSCategory ===
                                                      "Inkaso" ||
                                                  item.iSUcet ===
                                                      item.iSUcetA ? (
                                                    item.iSBatch ===
                                                    "CREDITKASA" ? (
                                                        <Accordion.Item
                                                            key={item.iSRefno}
                                                            eventKey={
                                                                item.iSRefno
                                                            }
                                                        >
                                                            <Accordion.Header>
                                                                <div>
                                                                    <Row
                                                                        xs={3}
                                                                        md={4}
                                                                    >
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                <Badge bg="secondary">
                                                                                    {
                                                                                        item.iSRefno
                                                                                    }
                                                                                </Badge>
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                {
                                                                                    item.iSSupplier
                                                                                }
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                {item.iSAmount.toLocaleString(
                                                                                    "cs-CZ",
                                                                                    {
                                                                                        style: "decimal",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                        useGrouping: true,
                                                                                    }
                                                                                ) +
                                                                                    " " +
                                                                                    (item.mena ===
                                                                                    "CZK"
                                                                                        ? "Kč"
                                                                                        : ` ${item.mena}`)}
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center d-none d-md-block">
                                                                            <h4 className="mb-0">
                                                                                <Badge
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            item.stateColor,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item.iSState
                                                                                    }
                                                                                </Badge>
                                                                            </h4>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="row">
                                                                    <div className="col-md-8 col-sm-12">
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        RefNo
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.iSRefno
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Stav
                                                                                        Případu:
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            className="badge"
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    item.stateColor,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item.iSState
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Splatnost:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.dueDate
                                                                                        }{" "}
                                                                                        (
                                                                                        {
                                                                                            item.DPD
                                                                                        }{" "}
                                                                                        dní
                                                                                        po
                                                                                        splatnosti)
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <hr />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <strong>
                                                                                            Věřitel
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Částka:
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.iSAmountO.toLocaleString(
                                                                                            "cs-CZ",
                                                                                            {
                                                                                                style: "decimal",
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2,
                                                                                                useGrouping: true,
                                                                                            }
                                                                                        )}
                                                                                        &nbsp;
                                                                                        {item.mena ===
                                                                                        "CZK"
                                                                                            ? "Kč"
                                                                                            : ` ${item.mena}`}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Číslo
                                                                                        učtu:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.iSUcet
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Variabilní
                                                                                        symbol:
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.iSVS ? (
                                                                                            item.iSVS
                                                                                        ) : (
                                                                                            <div className="badge bg-info">
                                                                                                Žádný
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <hr />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <strong>
                                                                                            Inkasní
                                                                                            agentura
                                                                                            (IA)
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Náklady
                                                                                        IA:
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.iSAmountD.toLocaleString(
                                                                                            "cs-CZ",
                                                                                            {
                                                                                                style: "decimal",
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2,
                                                                                                useGrouping: true,
                                                                                            }
                                                                                        )}
                                                                                        &nbsp;
                                                                                        {item.menaD ===
                                                                                        "CZK"
                                                                                            ? "Kč"
                                                                                            : ` ${item.menaD}`}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Číslo
                                                                                        učtu
                                                                                        IA:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.iSUcetD
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Variabilní
                                                                                        symbol
                                                                                        IA:
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.iSVS ? (
                                                                                            item.iSVS
                                                                                        ) : (
                                                                                            <div className="badge bg-info">
                                                                                                Žádný
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12 qrcodem d-flex flex-wrap gap-2">
                                                                        <fieldset>
                                                                            <legend>
                                                                                <strong>
                                                                                    Platba
                                                                                </strong>
                                                                            </legend>
                                                                            <img
                                                                                src={
                                                                                    "https://ispis.com/gateway/qrcode/" +
                                                                                    item.iSIban +
                                                                                    "/" +
                                                                                    item.iSVS +
                                                                                    "/" +
                                                                                    item.iSAmountO +
                                                                                    "/" +
                                                                                    item.iSCurrency +
                                                                                    "/QR?amp;size=6"
                                                                                }
                                                                                alt="qr-code"
                                                                            />
                                                                        </fieldset>
                                                                        <fieldset>
                                                                            <legend>
                                                                                <strong>
                                                                                    Platba
                                                                                    IA
                                                                                </strong>
                                                                            </legend>
                                                                            <img
                                                                                src={
                                                                                    "https://ispis.com/gateway/qrcode/" +
                                                                                    item.iSIbanD +
                                                                                    "/" +
                                                                                    item.iSVS +
                                                                                    "/" +
                                                                                    item.iSAmountD +
                                                                                    "/" +
                                                                                    item.iSCurrency +
                                                                                    "/QR?amp;size=6"
                                                                                }
                                                                                alt="qr-code"
                                                                            />
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ) : (
                                                        <Accordion.Item
                                                            key={item.iSRefno}
                                                            eventKey={
                                                                item.iSRefno
                                                            }
                                                        >
                                                            <Accordion.Header>
                                                                <div>
                                                                    <Row
                                                                        xs={3}
                                                                        md={4}
                                                                    >
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                <Badge bg="secondary">
                                                                                    {
                                                                                        item.iSRefno
                                                                                    }
                                                                                </Badge>
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                {
                                                                                    item.iSSupplier
                                                                                }
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center">
                                                                            <h4 className="mb-0">
                                                                                {(
                                                                                    parseFloat(
                                                                                        item.iSAmount
                                                                                    ) +
                                                                                    parseFloat(
                                                                                        item.iSAmountA
                                                                                    )
                                                                                ).toLocaleString(
                                                                                    "cs-CZ",
                                                                                    {
                                                                                        style: "decimal",
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2,
                                                                                        useGrouping: true,
                                                                                    }
                                                                                ) +
                                                                                    " " +
                                                                                    (item.mena ===
                                                                                    "CZK"
                                                                                        ? "Kč"
                                                                                        : ` ${item.mena}`)}
                                                                            </h4>
                                                                        </Col>
                                                                        <Col className="align-self-center d-none d-md-block">
                                                                            <h4 className="mb-0">
                                                                                <div
                                                                                    className="badge"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            item.stateColor,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item.iSState
                                                                                    }
                                                                                </div>
                                                                            </h4>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="row">
                                                                    <div className="col-md-8 col-sm-12">
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Stav
                                                                                        Případu:
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            className="badge"
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    item.stateColor,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item.iSState
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Splatnost:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.dueDate
                                                                                        }{" "}
                                                                                        (
                                                                                        {
                                                                                            item.DPD
                                                                                        }{" "}
                                                                                        dní
                                                                                        po
                                                                                        splatnosti)
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Částka:
                                                                                    </td>
                                                                                    <td>
                                                                                        {(
                                                                                            parseFloat(
                                                                                                item.iSAmount
                                                                                            ) +
                                                                                            parseFloat(
                                                                                                item.iSAmountA
                                                                                            )
                                                                                        ).toLocaleString(
                                                                                            "cs-CZ",
                                                                                            {
                                                                                                style: "decimal",
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2,
                                                                                                useGrouping: true,
                                                                                            }
                                                                                        )}{" "}
                                                                                        {item.mena ===
                                                                                        "CZK"
                                                                                            ? "Kč"
                                                                                            : ` ${item.mena}`}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Číslo
                                                                                        učtu:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.iSUcet
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Variabilní
                                                                                        symbol:
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.iSVS ? (
                                                                                            item.iSVS
                                                                                        ) : (
                                                                                            <div className="badge bg-info">
                                                                                                Žádný
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12 qrcodem d-flex flex-wrap gap-2">
                                                                        <fieldset>
                                                                            <legend>
                                                                                <strong>
                                                                                    Platba
                                                                                </strong>
                                                                            </legend>
                                                                            <img
                                                                                src={
                                                                                    "https://ispis.com/gateway/qrcode/" +
                                                                                    item.iSIban +
                                                                                    "/" +
                                                                                    item.iSVS +
                                                                                    "/" +
                                                                                    (
                                                                                        parseFloat(
                                                                                            item.iSAmount
                                                                                        ) +
                                                                                        parseFloat(
                                                                                            item.iSAmountA
                                                                                        )
                                                                                    ).toFixed(
                                                                                        0
                                                                                    ) +
                                                                                    "/" +
                                                                                    item.iSCurrency +
                                                                                    "/QR?amp;size=6"
                                                                                }
                                                                                alt="qr-code"
                                                                            />
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                ) : (
                                                    <Accordion.Item
                                                        key={item.iSRefno}
                                                        eventKey={item.iSRefno}
                                                    >
                                                        <Accordion.Header>
                                                            <div>
                                                                <Row
                                                                    xs={3}
                                                                    md={4}
                                                                >
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            <Badge bg="secondary">
                                                                                {
                                                                                    item.iSRefno
                                                                                }
                                                                            </Badge>
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            {
                                                                                item.iSSupplier
                                                                            }
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            {(
                                                                                parseFloat(
                                                                                    item.iSAmount
                                                                                ) +
                                                                                parseFloat(
                                                                                    item.iSAmountA
                                                                                )
                                                                            ).toLocaleString(
                                                                                "cs-CZ",
                                                                                {
                                                                                    style: "decimal",
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                    useGrouping: true,
                                                                                }
                                                                            ) +
                                                                                " " +
                                                                                (item.mena ===
                                                                                "CZK"
                                                                                    ? "Kč"
                                                                                    : ` ${item.mena}`)}
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center d-none d-md-block">
                                                                        <h4 className="mb-0">
                                                                            <div
                                                                                className="badge"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        item.stateColor,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.iSState
                                                                                }
                                                                            </div>
                                                                        </h4>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="col-md-8 col-sm-12">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Stav
                                                                                    Případu:
                                                                                </td>
                                                                                <td>
                                                                                    <div
                                                                                        className="badge"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                item.stateColor,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.iSState
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Splatnost:
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.dueDate
                                                                                    }{" "}
                                                                                    (
                                                                                    {
                                                                                        item.DPD
                                                                                    }{" "}
                                                                                    dní
                                                                                    po
                                                                                    splatnosti)
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <hr />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <strong>
                                                                                        Věřitel
                                                                                    </strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Částka:
                                                                                </td>
                                                                                <td>
                                                                                    {item.iSAmount.toLocaleString(
                                                                                        "cs-CZ",
                                                                                        {
                                                                                            style: "decimal",
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2,
                                                                                            useGrouping: true,
                                                                                        }
                                                                                    )}
                                                                                    &nbsp;
                                                                                    {item.mena ===
                                                                                    "CZK"
                                                                                        ? "Kč"
                                                                                        : ` ${item.mena}`}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Číslo
                                                                                    učtu:
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.iSUcet
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Variabilní
                                                                                    symbol:
                                                                                </td>
                                                                                <td>
                                                                                    {item.iSVS ? (
                                                                                        item.iSVS
                                                                                    ) : (
                                                                                        <div className="badge bg-info">
                                                                                            Žádný
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <hr />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <strong>
                                                                                        Advokát
                                                                                        (AK)
                                                                                    </strong>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Částka
                                                                                    AK:
                                                                                </td>
                                                                                <td>
                                                                                    {item.iSAmountA.toLocaleString(
                                                                                        "cs-CZ",
                                                                                        {
                                                                                            style: "decimal",
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2,
                                                                                            useGrouping: true,
                                                                                        }
                                                                                    )}{" "}
                                                                                    {item.menaA ===
                                                                                    "CZK"
                                                                                        ? "Kč"
                                                                                        : ` ${item.menaA}`}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Číslo
                                                                                    učtu
                                                                                    AK:
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.iSUcetA
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Variabilní
                                                                                    symbol
                                                                                    AK:
                                                                                </td>
                                                                                <td>
                                                                                    {item.iSVSA ? (
                                                                                        item.iSVSA
                                                                                    ) : (
                                                                                        <div className="badge bg-info">
                                                                                            Žádný
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="col-md-4 col-sm-12 qrcodem d-flex flex-wrap gap-2">
                                                                    <fieldset>
                                                                        <legend>
                                                                            <strong>
                                                                                Platba
                                                                            </strong>
                                                                        </legend>
                                                                        <img
                                                                            src={
                                                                                "https://ispis.com/gateway/qrcode/" +
                                                                                item.iSIban +
                                                                                "/" +
                                                                                item.iSVS +
                                                                                "/" +
                                                                                item.iSAmount +
                                                                                "/" +
                                                                                item.iSCurrency +
                                                                                "/QR?amp;size=6"
                                                                            }
                                                                            alt="qr-code"
                                                                        />
                                                                    </fieldset>
                                                                    <fieldset>
                                                                        <legend>
                                                                            <strong>
                                                                                Platba
                                                                                Advokát
                                                                            </strong>
                                                                        </legend>
                                                                        <img
                                                                            src={
                                                                                "https://ispis.com/gateway/qrcode/" +
                                                                                item.iSIbanA +
                                                                                "/" +
                                                                                item.iSVSA +
                                                                                "/" +
                                                                                item.iSAmountA +
                                                                                "/" +
                                                                                item.iSCurrencyA +
                                                                                "/QR?amp;size=6"
                                                                            }
                                                                            alt="qr-code"
                                                                        />
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            )}
                                        </Accordion>
                                    </>
                                )}
                            </Col>
                            <Col xs={12} md={3}>
                                <h2 className="mb-0">Kontaktní údaje</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        <p>
                                            <strong>Jméno a příjmení:</strong>{" "}
                                            {infoData.name}
                                        </p>
                                        <p>
                                            <strong>Adresa hlavní:</strong>{" "}
                                            {infoData.main_address}
                                        </p>
                                        <p>
                                            <strong>Adresa doručovací:</strong>{" "}
                                            {infoData.contact_address === "" ? (
                                                <Badge bg="danger">
                                                    Není vyplněno
                                                </Badge>
                                            ) : (
                                                infoData.contact_address
                                            )}
                                        </p>
                                        <p>
                                            <strong>Email:</strong>{" "}
                                            {infoData.email}
                                        </p>
                                        <p>
                                            <strong>Telefon:</strong>{" "}
                                            {infoData.phone}
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
}
