import {Badge, Col, Container, Row, Spinner} from "react-bootstrap";
import TopBar from "../components/TopBar";
import React, {useContext, useEffect, useReducer} from "react";
import {Data} from "../../Data";
import {toast} from "react-toastify";
import {getError} from "../../utils/utils";
import Axios from "axios";
import {Helmet} from "react-helmet-async";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {...state, loading: true};
        case "FETCH_SUCCESS":
            return {...state, infoData: action.payload, loading: false};
        case "FETCH_FAIL":
            return {...state, loading: false};
        default:
            return state;
    }
};

export default function Profile() {
    const [{loading, infoData}, dispatch] = useReducer(reducer, {
        infoData: [],
        loading: true,
    });
    const {state} = useContext(Data);
    const {userInfo} = state;
    useEffect(() => {
        const fetchData = async () => {
            dispatch({type: "FETCH_REQUEST"});
            try {
                const {data} = await Axios.post(
                    "/api/info/",
                    {
                        request: "info",
                    },
                    {
                        headers: {
                            authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                );
                dispatch({type: "FETCH_SUCCESS", payload: data});
                //console.log(data);
            } catch (err) {
                dispatch({type: "FETCH_FAIL"});
                toast.error(getError(err));
            }
        };
        fetchData();
    }, [userInfo]);
    return (
        <>
            <Helmet>
                <title>Údaje | De Vries Group, a.s.</title>
            </Helmet>
            <TopBar/>
            <main>
                <section className="home">
                    <Container>
                        <Row className="gy-4">
                            <Col xs={12} md={12}>
                                <h2 className="mb-0">Kontaktní údaje</h2>
                                <hr className="mb-4"/>
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        <p>
                                            <strong>Jméno a příjmení:</strong>{" "}
                                            {infoData.name}
                                        </p>
                                        <p>
                                            <strong>Adresa hlavní:</strong>{" "}
                                            {infoData.main_address}
                                        </p>
                                        <p>
                                            <strong>Adresa doručovací:</strong>{" "}
                                            {infoData.contact_address === "" ? (
                                                <Badge bg="danger">
                                                    Není vyplněno
                                                </Badge>
                                            ) : (
                                                infoData.contact_address
                                            )}
                                        </p>
                                        <p>
                                            <strong>Email:</strong>{" "}
                                            {infoData.email}
                                        </p>
                                        <p>
                                            <strong>Telefon:</strong>{" "}
                                            {infoData.phone}
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
}
