import {
    Container,
    InputGroup,
    Form,
    Button,
    Spinner,
    Row,
    Col,
} from "react-bootstrap";
import React, { useContext, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import Axios from "axios";
import { Data } from "../../../Data";
import { getError } from "../../../utils/utils";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, authData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default function AdminLogin() {
    const [birthNo, setBirthNo] = useState("");
    const [refNo, setRefNo] = useState("");
    const [password, setPassword] = useState("");

    const { state, dispatch: ctxDispatch } = useContext(Data);
    const { userInfo } = state;
    const [{ loading, authData }, dispatch] = useReducer(reducer, {
        authData: [],
        loading: false,
    });

    const authLoginHandler = async (e) => {
        e.preventDefault();
        dispatch({ type: "FETCH_REQUEST" });
        if ((birthNo.length < 10 && refNo.length < 2) || password.length < 6) {
            dispatch({ type: "FETCH_FAIL" });
            return toast.error("Všechna pole musí být vyplněna!");
        }
        try {
            const { data } = await Axios.post("/api/auth/auth-admin", {
                birthNo: birthNo,
                refNo: refNo,
                password: password,
            });
            ctxDispatch({ type: "USER_SIGNIN", payload: data });
            localStorage.setItem("userInfo", JSON.stringify(data));
            dispatch({ type: "FETCH_SUCCESS" });
            toast.success("Úspěšně přihlášen!");
        } catch (err) {
            dispatch({ type: "FETCH_FAIL" });
            toast.error(getError(err));
        }
    };

    if (userInfo) {
        return <Navigate to="/" />;
    } else {
        return (
            <>
                <Helmet>
                    <title>Portál pro dlužníky | De Vries Group, a.s.</title>
                </Helmet>
                <main>
                    <section className="login">
                        <Container>
                            <div className="login-container">
                                <h1 className="mb-5">Admin Login</h1>
                                <Form onSubmit={authLoginHandler}>
                                    <Row
                                        xs={1}
                                        md={2}
                                        className="gy-2 position-relative"
                                    >
                                        <Col>
                                            <Form.Label htmlFor="birthno">
                                                Rodné číslo (bez lomítka)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                {refNo.length > 0 ? (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setBirthNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        id="birthno"
                                                        placeholder=" "
                                                        aria-label="Rodné číslo (bez lomítka)"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        minLength={10}
                                                        maxLength={10}
                                                        value={birthNo}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setBirthNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        id="birthno"
                                                        placeholder=" "
                                                        aria-label="Rodné číslo (bez lomítka)"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        minLength={10}
                                                        maxLength={10}
                                                        value={birthNo}
                                                    />
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <span className="text text-small position-absolute translate-middle-x">
                                            <FontAwesomeIcon
                                                className="me-2"
                                                icon={faArrowLeft}
                                            />
                                            nebo
                                            <FontAwesomeIcon
                                                className="ms-2"
                                                icon={faArrowRight}
                                            />
                                        </span>
                                        <Col>
                                            <Form.Label htmlFor="refno">
                                                Referenční číslo
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                {birthNo.length > 0 ? (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setRefNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder=" "
                                                        aria-label="Referenční číslo"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        id="refno"
                                                        minLength={2}
                                                        maxLength={30}
                                                        value={refNo}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setRefNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder=" "
                                                        aria-label="Referenční číslo"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        id="refno"
                                                        minLength={2}
                                                        maxLength={30}
                                                        value={refNo}
                                                    />
                                                )}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Form.Label htmlFor="password">
                                        Heslo
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder=" "
                                            aria-label="Heslo"
                                            aria-describedby="basic-addon1"
                                            type="password"
                                            id="password"
                                            minLength={6}
                                            maxLength={30}
                                            value={password}
                                        />
                                    </InputGroup>
                                    <div className="mb-2 d-flex gap-3 align-items-center">
                                        <Button variant="primary" type="submit">
                                            Přihlásit
                                        </Button>
                                        {loading && (
                                            <>
                                                <div>
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </Container>
                    </section>
                </main>
            </>
        );
    }
}
