import {
    Badge,
    Button,
    Col,
    Container,
    Form,
    Row,
    Spinner,
} from "react-bootstrap";
import TopBar from "../components/TopBar";
import { Helmet } from "react-helmet-async";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Data } from "../../Data";
import Axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../utils/utils";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, resData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        case "SEND_REQUEST":
            return { ...state, sendLoading: true };
        case "SEND_SUCCESS":
            return { ...state, sendLoading: false };
        case "SEND_FAIL":
            return { ...state, sendLoading: false };
        default:
            return state;
    }
};
export default function Requests() {
    const { state, dispatch: ctxDispatch } = useContext(Data);
    const { userInfo } = state;

    const [file, setFile] = useState(undefined);
    const [refNo, setRefNo] = useState(undefined);
    const [category, setCategory] = useState(undefined);
    const [subject, setSubject] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const [{ loading, sendLoading, resData }, dispatch] = useReducer(reducer, {
        resData: [],
        loading: true,
        sendLoading: false,
    });

    const sendForm = async (e) => {
        e.preventDefault();
        dispatch({ type: "SEND_REQUEST" });
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("refNo", refNo);
            formData.append("category", category);
            formData.append("subject", subject);
            formData.append("message", message);

            await Axios.post("/api/request/form", formData, {
                headers: {
                    authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: "SEND_SUCCESS" });
            toast.success("Žádost byla úspěšně odeslána!");
            setFile(null);
            setRefNo(null);
            setCategory(null);
            setSubject(null);
            setMessage(null);
            await updateData();
            //console.log(data);
        } catch (err) {
            dispatch({ type: "SEND_FAIL" });
            toast.error(getError(err));
        }
    };

    const updateData = async () => {
        dispatch({ type: "FETCH_REQUEST" });
        try {
            const { data } = await Axios.post(
                "/api/request/",
                {
                    request: "info",
                },
                {
                    headers: {
                        authorization: `Bearer ${userInfo.token}`,
                    },
                }
            );
            dispatch({ type: "FETCH_SUCCESS", payload: data });
            //console.log(data);
        } catch (err) {
            dispatch({ type: "FETCH_FAIL" });
            toast.error(getError(err));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "FETCH_REQUEST" });
            try {
                const { data } = await Axios.post(
                    "/api/request/",
                    {
                        request: "info",
                    },
                    {
                        headers: {
                            authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                );
                dispatch({ type: "FETCH_SUCCESS", payload: data });
                //console.log(data);
            } catch (err) {
                dispatch({ type: "FETCH_FAIL" });
                toast.error(getError(err));
            }
        };
        fetchData();
    }, [userInfo]);

    return (
        <>
            <Helmet>
                <title>Žádosti | De Vries Group, a.s.</title>
            </Helmet>
            <TopBar />
            <main>
                <section className="home">
                    <Container>
                        <Row className="gy-4">
                            <Col xs={12} md={8}>
                                <h2 className="mb-0">Žádosti</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : resData ? (
                                    <>
                                        <div className="accordion-heading">
                                            <Row xs={3} md={4}>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Číslo
                                                    </h3>
                                                </Col>
                                                <Col className="d-none d-md-block">
                                                    <h3 className="mb-0">
                                                        Vytvoření
                                                    </h3>
                                                </Col>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Změna
                                                    </h3>
                                                </Col>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Status
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Accordion alwaysOpen>
                                            {resData?.requests === null ||
                                            resData?.requests === undefined ||
                                            resData?.requests.length <= 0 ? (
                                                <Alert variant={"info"}>
                                                    Nemáte žádné žádosti
                                                </Alert>
                                            ) : (
                                                resData.requests.map((item) => (
                                                    <Accordion.Item
                                                        key={item.id}
                                                        eventKey={item.id}
                                                    >
                                                        <Accordion.Header>
                                                            <div>
                                                                <Row
                                                                    xs={3}
                                                                    md={4}
                                                                >
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            <Badge bg="secondary">
                                                                                {
                                                                                    item.id
                                                                                }
                                                                            </Badge>
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center d-none d-md-block">
                                                                        <h4 className="mb-0">
                                                                            {new Date(
                                                                                item.datum_vytvoreni
                                                                            ).toLocaleDateString()}
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            {new Date(
                                                                                item.datum_zmeny
                                                                            ).toLocaleDateString()}
                                                                        </h4>
                                                                    </Col>
                                                                    <Col className="align-self-center">
                                                                        <h4 className="mb-0">
                                                                            {resData.states.map(
                                                                                (
                                                                                    item2
                                                                                ) =>
                                                                                    parseInt(
                                                                                        item2.id
                                                                                    ) ===
                                                                                        parseInt(
                                                                                            item.stav
                                                                                        ) && (
                                                                                        <div
                                                                                            key={
                                                                                                item2.id
                                                                                            }
                                                                                            className="badge"
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    item2.color,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item2.nazev_stavu
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                            )}
                                                                        </h4>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="mb-3">
                                                                <strong>
                                                                    Typ:{" "}
                                                                </strong>
                                                                {resData.categories.map(
                                                                    (item2) =>
                                                                        parseInt(
                                                                            item2.id
                                                                        ) ===
                                                                            parseInt(
                                                                                item.kategorie
                                                                            ) && (
                                                                            <span
                                                                                key={
                                                                                    item2.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item2.nazev_kategorie
                                                                                }
                                                                            </span>
                                                                        )
                                                                )}
                                                            </p>
                                                            <p className="mb-3">
                                                                <strong>
                                                                    Předmět:{" "}
                                                                </strong>
                                                                <span>
                                                                    {
                                                                        item.predmet
                                                                    }
                                                                </span>
                                                            </p>
                                                            <p className="mb-3">
                                                                <strong>
                                                                    Zpráva:{" "}
                                                                </strong>
                                                                <span>
                                                                    {item.text}
                                                                </span>
                                                            </p>
                                                            <p className="mb-3">
                                                                <strong>
                                                                    Soubor:{" "}
                                                                </strong>
                                                                <span>
                                                                    {item.priloha ===
                                                                        "undefined" ||
                                                                    item.priloha ===
                                                                        "null" ||
                                                                    item.priloha ===
                                                                        "" ||
                                                                    item.priloha ===
                                                                        undefined ||
                                                                    item.priloha ===
                                                                        null ? (
                                                                        <Badge bg="danger">
                                                                            Není
                                                                            vyplněno
                                                                        </Badge>
                                                                    ) : (
                                                                        <a
                                                                            href={
                                                                                "https://data.devriesgroup.cz/" +
                                                                                item.priloha
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Odkaz
                                                                        </a>
                                                                    )}
                                                                </span>
                                                            </p>
                                                            <hr />
                                                            <p className="mb-3">
                                                                <strong>
                                                                    Výsledek
                                                                    žádosti:{" "}
                                                                </strong>
                                                                <span>
                                                                    {item.odpoved ===
                                                                    undefined ? (
                                                                        <Badge bg="warning">
                                                                            Žádná
                                                                        </Badge>
                                                                    ) : (
                                                                        <span>
                                                                            <i>
                                                                                {new Date(
                                                                                    item.odpoved.rdate
                                                                                ).toLocaleDateString()}
                                                                            </i>{" "}
                                                                            -{" "}
                                                                            {
                                                                                item
                                                                                    .odpoved
                                                                                    .remark
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))
                                            )}
                                        </Accordion>
                                    </>
                                ) : (
                                    <h1>Chyba</h1>
                                )}
                            </Col>
                            <Col xs={12} md={4}>
                                <h2 className="mb-0">Nová žádost</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        <Form onSubmit={(e) => sendForm(e)}>
                                            <Row className="gy-3 gx-3">
                                                <Col xs={12} md={6}>
                                                    <Form.Group controlId="form.refno">
                                                        <Form.Label>
                                                            Referenční číslo
                                                        </Form.Label>
                                                        <Form.Select
                                                            required={true}
                                                            value={refNo}
                                                            onChange={(e) =>
                                                                setRefNo(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option
                                                                hidden={true}
                                                            />
                                                            {resData?.cumulativeRefNo !==
                                                                undefined &&
                                                                resData?.cumulativeRefNo.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group controlId="form.category">
                                                        <Form.Label>
                                                            Kategorie
                                                        </Form.Label>
                                                        <Form.Select
                                                            required={true}
                                                            value={category}
                                                            onChange={(e) =>
                                                                setCategory(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option
                                                                hidden={true}
                                                            />
                                                            {resData?.categories !==
                                                                undefined &&
                                                                resData?.categories.map(
                                                                    (item) => (
                                                                        <option
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.nazev_kategorie
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <Form.Group controlId="form.name">
                                                        <Form.Label>
                                                            Předmět
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={subject}
                                                            onChange={(e) =>
                                                                setSubject(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required={true}
                                                            minLength={5}
                                                            maxLength={50}
                                                            type="text"
                                                            placeholder=" "
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <Form.Group controlId="form.message">
                                                        <Form.Label>
                                                            Zpráva
                                                        </Form.Label>
                                                        <Form.Control
                                                            value={message}
                                                            onChange={(e) =>
                                                                setMessage(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required={true}
                                                            as="textarea"
                                                            minLength={10}
                                                            maxLength={500}
                                                            rows={5}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <Form.Group controlId="form.file">
                                                        <Form.Label>
                                                            Příloha
                                                        </Form.Label>
                                                        <Form.Control
                                                            accept=".png, .jpeg, .jpg, .pdf, .zip"
                                                            onChange={(e) =>
                                                                setFile(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                            type="file"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    md={4}
                                                    className="align-items-end d-flex"
                                                >
                                                    <div className="d-flex w-100 align-items-center gap-3">
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            className="w-100"
                                                        >
                                                            Odeslat
                                                        </Button>
                                                        {sendLoading && (
                                                            <div>
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">
                                                                        Loading...
                                                                    </span>
                                                                </Spinner>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
}
