import { Routes, Route } from "react-router-dom";
import Main from "./login/Main";

export default function Login() {
    return (
        <Routes>
            <Route path="/" element={<Main />}></Route>
        </Routes>
    );
}
