import "./sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./modules/pages/Login";
import Home from "./modules/pages/Home";
import ProtectedContent from "./modules/utils/ProtectedContent";
import Requests from "./modules/pages/Requests";
import Error from "./modules/pages/Error";
import Profile from "./modules/pages/Profile";
import Documents from "./modules/pages/Documents";
import MessengerChat from "./modules/components/MessengerChat";
import AdminLogin from "./modules/pages/login/Admin";

function App() {
    return (
        <BrowserRouter>
            <ToastContainer />
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedContent>
                            <MessengerChat />
                            <Home />
                        </ProtectedContent>
                    }
                ></Route>
                <Route
                    path="/profile"
                    element={
                        <ProtectedContent>
                            <MessengerChat />
                            <Profile />
                        </ProtectedContent>
                    }
                ></Route>
                <Route
                    path="/documents"
                    element={
                        <ProtectedContent>
                            <MessengerChat />
                            <Documents />
                        </ProtectedContent>
                    }
                ></Route>
                <Route
                    path="/requests"
                    element={
                        <ProtectedContent>
                            <MessengerChat />
                            <Requests />
                        </ProtectedContent>
                    }
                ></Route>
                <Route path="/login/*" element={<Login />}></Route>
                <Route path="/admin" element={<AdminLogin />}></Route>
                <Route path="/404" element={<Error />}></Route>
                <Route path="*" element={<Navigate to="/404" />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
