import {Button, Container} from "react-bootstrap";
import logo from "../../assets/logo.png";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";

export default function Error() {
    return (
        <>
            <Helmet>
                <title>404 - Stránka neexistuje | De Vries Group, a.s.</title>
            </Helmet>
            <main>
                <section className="error">
                    <Container>
                        <Link to={"/"} className="mb-5 pb-5">
                            <img width={240} src={logo} alt="logo" />
                        </Link>
                        <h1 className="mt-5">404</h1>
                        <p>Tato stránka neexistuje.</p>
                        <Link className="btn btn-primary" to="/">Přejít domů</Link>
                    </Container>
                </section>
            </main>
        </>
    );
}