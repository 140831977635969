import {
    Container,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Form,
    Button,
    Spinner,
    Row,
    Col,
} from "react-bootstrap";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { Navigate, useLocation } from "react-router-dom";
import Axios from "axios";
import { Data } from "../../../Data";
import { getError } from "../../../utils/utils";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, authData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        case "FETCH_REQUEST_BANKID":
            return { ...state, loadingBankid: true };
        case "FETCH_SUCCESS_BANKID":
            return { ...state, loadingBankid: false };
        case "FETCH_FAIL_BANKID":
            return { ...state, loadingBankid: false };
        case "FETCH_REQUEST_AUTH":
            return { ...state, loadingAuth: true };
        case "FETCH_SUCCESS_AUTH":
            return { ...state, loadingAuth: false };
        case "FETCH_FAIL_AUTH":
            return { ...state, loadingAuth: false };
        case "FETCH_REQUEST_LOGIN":
            return { ...state, loadingLogin: true };
        case "FETCH_SUCCESS_LOGIN":
            return { ...state, loadingLogin: false };
        case "FETCH_FAIL_LOGIN":
            return { ...state, loadingLogin: false };
        default:
            return state;
    }
};

export default function Main() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [authStatus, setAuthStatus] = useState(0);
    const [showAuth, setShowAuth] = useState(false);
    const handleCloseAuth = () => {
        setShowAuth(false);
        setAuthStatus(0);
    };

    const [authToken, setAuthToken] = useState("");
    const [authCode, setAuthCode] = useState("");

    const [birthNo, setBirthNo] = useState("");
    const [refNo, setRefNo] = useState("");
    const { state, dispatch: ctxDispatch } = useContext(Data);
    const { userInfo } = state;
    const [
        { loading, loadingAuth, loadingLogin, loadingBankid, authData },
        dispatch,
    ] = useReducer(reducer, {
        authData: [],
        loading: false,
        loadingAuth: false,
        loadingLogin: false,
        loadingBankid: false,
    });

    const authEndpoint =
        process.env.REACT_APP_ENV === "development"
            ? "https://oidc.sandbox.bankid.cz/auth"
            : "https://oidc.bankid.cz/auth";
    // dev : https://oidc.sandbox.bankid.cz/auth
    const loginEndpoint =
        process.env.REACT_APP_ENV === "development"
            ? "http://localhost:3000/login"
            : "https://portal.devriesgroup.cz/login";
    // dev : http://localhost:3000/login

    const scopes = [
        "openid",
        "profile.birthdate",
        "profile.email",
        "profile.locale",
        "profile.name",
        "profile.phonenumber",
        "profile.updatedat",
        "profile.zoneinfo",
    ];

    const authUriParams = {
        client_id: "242ef172-bd71-469d-af9f-d92ae759b704",
        scope: scopes.join(" "),
        redirect_uri: loginEndpoint,
        // dev : http://localhost:3000/login
        //redirect_uri: process.env.REACT_APP_ENV === "production" ? "https://portal.devriesgroup.cz/login" : "http://localhost:3000/login",
        response_type: "code",
    };

    const uriParams = new URLSearchParams(authUriParams);

    const authUri = `${authEndpoint}?${uriParams}`;

    useEffect(() => {
        const accessCode = queryParams.get("code");
        const message = queryParams.get("message");

        if (message) {
            switch (message) {
                case "success":
                    toast.success("Úspěch");
                    break;
                case "error":
                    toast.error("Chyba");
                    break;
                case "logout":
                    toast.info("Odhlášení proběhlo úspěšně");
                    break;
                default:
                    toast.info(message);
                    break;
            }
            queryParams.delete("message");
            window.history.replaceState({}, "", location.pathname);
        }

        if (accessCode) {
            const bankidHandler = async () => {
                dispatch({ type: "FETCH_REQUEST_BANKID" });
                try {
                    const { data } = await Axios.post(
                        "/api/auth/bankid",
                        {},
                        {
                            headers: {
                                code: `Bearer ${accessCode}`,
                            },
                        }
                    );
                    dispatch({ type: "FETCH_SUCCESS_BANKID" });
                    ctxDispatch({ type: "USER_SIGNIN", payload: data });
                    localStorage.setItem("userInfo", JSON.stringify(data));
                    toast.success("Úspěšně přihlášen!");
                } catch (err) {
                    dispatch({ type: "FETCH_FAIL_BANKID" });
                    toast.error(getError(err));
                }
            };
            bankidHandler();
            //console.log(accessToken);
        }
    }, []);

    const authHandler = async (e, method) => {
        e.preventDefault();
        setAuthCode("");
        dispatch({ type: "FETCH_REQUEST_AUTH" });
        try {
            const data = await Axios.post("/api/auth/auth", {
                method: method,
                birthNo: birthNo,
                refNo: refNo,
            });
            setAuthToken(data.data);
            dispatch({ type: "FETCH_SUCCESS_AUTH" });
            toast.info("Zadejte prosím ověřovací kód.");
            setAuthStatus(1);
        } catch (err) {
            dispatch({ type: "FETCH_FAIL_AUTH" });
            toast.error(getError(err));
        }
    };

    const authLoginHandler = async (e) => {
        e.preventDefault();
        dispatch({ type: "FETCH_REQUEST_LOGIN" });
        try {
            const { data } = await Axios.post(
                "/api/auth/auth-login",
                {
                    code: authCode,
                    birthNo: birthNo,
                    refNo: refNo,
                },
                {
                    headers: {
                        session: `Bearer ${authToken}`,
                    },
                }
            );
            ctxDispatch({ type: "USER_SIGNIN", payload: data });
            localStorage.setItem("userInfo", JSON.stringify(data));
            dispatch({ type: "FETCH_SUCCESS_LOGIN" });
            toast.success("Úspěšně přihlášen!");
        } catch (err) {
            dispatch({ type: "FETCH_FAIL_LOGIN" });
            toast.error(getError(err));
            setAuthCode("");
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setAuthCode("");
        dispatch({ type: "FETCH_REQUEST" });
        try {
            if (birthNo.length <= 0 && refNo.length <= 0) {
                toast.error("Zadejte prosím referenční, nebo rodné číslo!");
                dispatch({ type: "FETCH_FAIL" });
                return;
            }
            const { data } = await Axios.post("/api/auth/login", {
                birthNo: birthNo,
                refNo: refNo,
            });
            dispatch({ type: "FETCH_SUCCESS", payload: data });
            toast.info("Ověřte prosím Vaši totožnost");
            setShowAuth(true);
        } catch (err) {
            dispatch({ type: "FETCH_FAIL", payload: getError(err) });
            toast.error(getError(err));
            setRefNo("");
            setBirthNo("");
        }
    };

    if (userInfo) {
        return <Navigate to="/" />;
    } else {
        return (
            <>
                <Helmet>
                    <title>Portál pro dlužníky | De Vries Group, a.s.</title>
                </Helmet>
                <main>
                    <Modal
                        show={showAuth}
                        onHide={handleCloseAuth}
                        backdrop="static"
                        keyboard={false}
                        size="md"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h2 className="mb-0">OVĚŘENÍ</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {authStatus === 0 ? (
                                <>
                                    <h3 className="mb-4">
                                        Kam chcete zaslat přístupový kód?{" "}
                                    </h3>
                                    {loadingAuth && (
                                        <div>
                                            <Spinner
                                                animation="border"
                                                role="status"
                                            >
                                                <span className="visually-hidden">
                                                    Loading...
                                                </span>
                                            </Spinner>
                                        </div>
                                    )}
                                    <Row xs={1} md={2} className="gy-2">
                                        <Col>
                                            <Button
                                                variant="primary"
                                                className="w-100"
                                                onClick={(e) =>
                                                    authHandler(e, "email")
                                                }
                                            >
                                                <h4>E-mail</h4>
                                                {authData.email}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                variant="primary"
                                                className="w-100"
                                                onClick={(e) =>
                                                    authHandler(e, "sms")
                                                }
                                            >
                                                <h4>Telefon</h4>
                                                {authData.phone}
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <h3 className="mb-4">
                                        Zadejte přístupový kód
                                    </h3>
                                    <Form onSubmit={(e) => authLoginHandler(e)}>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Přístupový kód"
                                                aria-label="Přístupový kód"
                                                aria-describedby="basic-addon1"
                                                value={authCode}
                                                minLength={6}
                                                maxLength={6}
                                                onChange={(e) =>
                                                    setAuthCode(e.target.value)
                                                }
                                            />
                                        </InputGroup>
                                        <div className="d-flex align-items-center gap-3">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                            >
                                                Odeslat
                                            </Button>
                                            {loadingLogin && (
                                                <div>
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Modal.Body>
                    </Modal>
                    <section className="login">
                        <Container>
                            <div className="login-container">
                                <h1>Login</h1>
                                <Form onSubmit={submitHandler}>
                                    <div className="tootlit-container">
                                        <p>
                                            Přihlaste se přes BankID
                                            (doporučeno), nebo zadejte Vaše
                                            rodné číslo nebo{" "}
                                        </p>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    Referenční číslo je označení
                                                    pohledávky, které jste
                                                    obdrželi mailem, případně v
                                                    dopise.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="tooltip-trigger">
                                                referenční číslo
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                    {loadingBankid && (
                                        <div className="loading-box-global">
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                className="spinner-border-custom"
                                            >
                                                <span className="visually-hidden">
                                                    Loading...
                                                </span>
                                            </Spinner>
                                            <h1>
                                                Načítání, prosím vyčkejte...
                                            </h1>
                                        </div>
                                    )}
                                    <a
                                        href={authUri}
                                        className="button button-bankid mt-1"
                                    >
                                        <svg
                                            width="190"
                                            height="28"
                                            viewBox="0 0 190 38"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-30 w-70 pr-10 mr-10 border-r border-white-100"
                                        >
                                            <path
                                                d="M162.165 7.511h8.601c6.777 0 10.165 5.634 10.165 11.216 0 5.634-3.388 11.215-10.165 11.215h-8.601v-2.608h3.649V10.12h-3.649V7.51zm-4.952 29.473h13.814c12.354 0 18.506-9.128 18.506-18.257 0-9.13-6.152-18.258-18.506-18.258h-13.814v36.515zM143.139 7.511h7.819V.47h-7.819v7.042zm0 2.609h7.819v26.864h-7.819V10.12zm-32.528 26.864v-8.972h2.606l7.82 8.972h7.767v-2.608l-9.852-11.059 9.591-10.59V10.12h-7.454l-7.872 8.972v3.6h-2.606V.468h-7.819v36.515h7.819zm-21.633 0h7.819V20.71c0-6.73-4.9-11.111-10.374-11.111-2.294 0-4.691.782-6.829 2.452v3.025h-2.606V10.12H70.21v26.864h7.82V22.117c0-3.755 2.867-5.738 5.681-5.738 2.659 0 5.266 1.774 5.266 5.478v15.127zM49.465 16.275c3.492 0 6.411 2.817 6.411 7.25 0 4.435-2.919 7.252-6.411 7.252-3.493 0-6.62-2.817-6.62-7.251s3.127-7.251 6.62-7.251zm-14.7 7.25c0 8.66 6.203 13.981 12.354 13.981 2.45 0 4.9-.835 6.933-2.712v-2.765h2.606v4.955h7.298V10.12h-7.298v4.955h-2.606v-2.764c-2.033-1.879-4.483-2.713-6.933-2.713-6.151 0-12.355 5.32-12.355 13.928zM8.23 6.99h9.488c2.919 0 4.43 2.086 4.43 4.173 0 2.086-1.511 4.173-4.43 4.173h-6.881v6.781h7.558c2.92 0 4.431 2.087 4.431 4.174 0 2.086-1.512 4.173-4.43 4.173H8.23V6.99zm19.6 12.78h-4.274v-2.608h3.753c2.138-1.774 3.232-4.33 3.232-6.938 0-4.851-3.805-9.755-11.729-9.755H.151v36.515h19.392c7.976 0 11.834-5.06 11.834-10.015 0-2.765-1.2-5.425-3.545-7.199z"
                                                fill="#fff"
                                            ></path>
                                        </svg>
                                        Přihlásit se
                                    </a>

                                    <div className="or">
                                        <hr />
                                        <span className="text">nebo</span>
                                    </div>
                                    <Row
                                        xs={1}
                                        md={2}
                                        className="gy-2 position-relative"
                                    >
                                        <Col>
                                            <Form.Label htmlFor="birthno">
                                                Rodné číslo (bez lomítka)
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                {refNo.length > 0 ? (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setBirthNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        id="birthno"
                                                        placeholder=" "
                                                        aria-label="Rodné číslo (bez lomítka)"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        minLength={10}
                                                        maxLength={10}
                                                        value={birthNo}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setBirthNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        id="birthno"
                                                        placeholder=" "
                                                        aria-label="Rodné číslo (bez lomítka)"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        minLength={10}
                                                        maxLength={10}
                                                        value={birthNo}
                                                    />
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <span className="text text-small position-absolute translate-middle-x">
                                            <FontAwesomeIcon
                                                className="me-2"
                                                icon={faArrowLeft}
                                            />
                                            nebo
                                            <FontAwesomeIcon
                                                className="ms-2"
                                                icon={faArrowRight}
                                            />
                                        </span>
                                        <Col>
                                            <Form.Label htmlFor="refno">
                                                Referenční číslo
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                {birthNo.length > 0 ? (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setRefNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder=" "
                                                        aria-label="Referenční číslo"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        id="refno"
                                                        minLength={2}
                                                        maxLength={30}
                                                        value={refNo}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        onChange={(e) =>
                                                            setRefNo(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder=" "
                                                        aria-label="Referenční číslo"
                                                        aria-describedby="basic-addon1"
                                                        type="text"
                                                        id="refno"
                                                        minLength={2}
                                                        maxLength={30}
                                                        value={refNo}
                                                    />
                                                )}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <div className="mb-2 d-flex gap-3 align-items-center">
                                        <Button variant="primary" type="submit">
                                            Přihlásit
                                        </Button>
                                        {loading && (
                                            <>
                                                <div>
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </Container>
                    </section>
                </main>
            </>
        );
    }
}
